import { validDefined } from "src/../../src/types/defined";
import {
  StringNotEmpty,
  validStringNotEmpty,
} from "src/../../src/types/string-not-empty";
import {
  StringNormalizedFieldAlias,
  validStringNormalizedFieldAlias,
} from "../../../../src/types/string-normalized-field-alias";
import { FieldAliasInterface } from "../../../../src/interfaces/field-alias";
import { DatasourceType } from "src/../../src/types/datasource-type";

export abstract class FieldAlias<
  T extends "nd" | "nm" | "cd" | "cm" = "nd" | "nm" | "cd" | "cm"
> implements FieldAliasInterface<T>
{
  private static _nameFromAlias(str: string): StringNotEmpty {
    if (!str.startsWith('"') || !str.endsWith('"')) {
      return validStringNotEmpty(str);
    }
    try {
      return JSON.parse(str);
    } catch {
      return validStringNotEmpty(str);
    }
  }

  isNative(): this is FieldAliasInterface<T & ("nd" | "nm")> {
    return this.type === "nd" || this.type === "nm";
  }

  isCustom(): this is FieldAliasInterface<T & ("cd" | "cm")> {
    return this.type === "cd" || this.type === "cm";
  }

  isDimension(): this is FieldAliasInterface<T & ("nd" | "cd")> {
    return this.type === "nd" || this.type === "cd";
  }

  isMetric(): this is FieldAliasInterface<T & ("nm" | "cm")> {
    return this.type === "nm" || this.type === "cm";
  }

  abstract readonly type: T;
  abstract readonly datasourceType: {
    nd: DatasourceType;
    nm: DatasourceType;
    cd: null;
    cm: null;
  }[T];
  abstract readonly name: StringNotEmpty;

  public static createNativeDimension(
    datasourceType: DatasourceType,
    name: StringNotEmpty
  ): FieldAlias<"nd"> {
    return new NativeFieldAlias("nd", datasourceType, name);
  }

  public static createNativeMetric(
    datasourceType: DatasourceType,
    name: StringNotEmpty
  ): FieldAlias<"nm"> {
    return new NativeFieldAlias("nm", datasourceType, name);
  }

  public static createCustomDimension(name: StringNotEmpty): FieldAlias<"cd"> {
    return new CustomFieldAlias("cd", name);
  }

  public static createCustomMetric(name: StringNotEmpty): FieldAlias<"cm"> {
    return new CustomFieldAlias("cm", name);
  }

  public static createFromString(alias: StringNotEmpty): FieldAlias {
    const SEPARATOR = ":";
    const parts = alias.split(SEPARATOR);
    try {
      const type = parts[0];
      switch (type) {
        case "nd":
          return FieldAlias.createNativeDimension(
            validDefined(parts[1]) as DatasourceType, //TODO validate  as DatasourceType
            FieldAlias._nameFromAlias(parts.slice(2).join(SEPARATOR))
          );
        case "nm":
          return FieldAlias.createNativeMetric(
            validDefined(parts[1]) as DatasourceType, //TODO validate  as DatasourceType
            FieldAlias._nameFromAlias(parts.slice(2).join(SEPARATOR))
          );
        case "cd":
          return FieldAlias.createCustomDimension(
            FieldAlias._nameFromAlias(parts.slice(1).join(SEPARATOR))
          );
        case "cm":
          return FieldAlias.createCustomMetric(
            FieldAlias._nameFromAlias(parts.slice(1).join(SEPARATOR))
          );
      }
    } catch (e) {
      console.debug("FieldAlias: Unsupported field alias", alias, e);
      throw new Error("Unsupported field alias " + alias);
    }

    console.debug("FieldAlias: Unsupported field alias", alias);
    throw new Error("Unsupported field alias " + alias);
  }

  public static isFieldAlias(alias: string): alias is StringNotEmpty {
    const SEPARATOR = ":";
    const parts = alias.split(SEPARATOR, 1);
    const type = validDefined(parts[0]);
    switch (type) {
      case "nd":
      case "nm":
      case "cd":
      case "cm":
        return true;
    }

    return false;
  }

  public abstract toString(): StringNormalizedFieldAlias;

  public abstract equals(other: FieldAliasInterface): boolean;
}

export class NativeFieldAlias<T extends "nd" | "nm"> extends FieldAlias<T> {
  private readonly _str: StringNormalizedFieldAlias;

  public constructor(
    public readonly type: T,
    public readonly datasourceType: DatasourceType,
    public readonly name: StringNotEmpty
  ) {
    super();
    this._str = this._toString();
  }

  private _toString(): StringNormalizedFieldAlias {
    return validStringNormalizedFieldAlias(
      [
        this.type,
        this.datasourceType,
        /^[a-zA-Z0-9_.]+$/.test(this.name)
          ? this.name
          : JSON.stringify(this.name),
      ].join(":")
    );
  }

  public toString(): StringNormalizedFieldAlias {
    return this._str;
  }

  public equals(other: FieldAliasInterface): boolean {
    return (
      other.type === this.type &&
      other.datasourceType === this.datasourceType &&
      other.name === this.name
    );
  }
}

export class CustomFieldAlias<T extends "cd" | "cm"> extends FieldAlias<T> {
  private readonly _str: StringNormalizedFieldAlias;

  public readonly datasourceType: null = null;

  public constructor(
    public readonly type: T,
    public readonly name: StringNotEmpty
  ) {
    super();
    this._str = this._toString();
  }

  private _toString(): StringNormalizedFieldAlias {
    return validStringNormalizedFieldAlias(
      [
        this.type,
        /^[a-zA-Z0-9_.]+$/.test(this.name)
          ? this.name
          : JSON.stringify(this.name),
      ].join(":")
    );
  }

  public toString(): StringNormalizedFieldAlias {
    return this._str;
  }

  public equals(other: FieldAliasInterface): boolean {
    return other.type === this.type && other.name === this.name;
  }
}

export const FIELD_ALIAS_IQ_DATE = FieldAlias.createFromString(
  "nd:iq:date" as StringNotEmpty
);
export const FIELD_ALIAS_IQ_SOURCE_DATASOURCE_TYPE =
  FieldAlias.createFromString(
    "nd:iq:source.data_source.type" as StringNotEmpty
  );
export const FIELD_ALIAS_IQ_SOURCE_DATASOURCE_TEMPLATE_ID =
  FieldAlias.createFromString(
    "nd:iq:source.data_source.template.id" as StringNotEmpty
  );
export const FIELD_ALIAS_CD_IQ_DATASOURCE_NAME = FieldAlias.createFromString(
  "cd:iq.datasource.name"
);
export const FIELD_ALIAS_CD_IQ_YEAR = FieldAlias.createFromString("cd:iq.year");
export const FIELD_ALIAS_CD_IQ_MONTH =
  FieldAlias.createFromString("cd:iq.month");
