import {
  assertNumberInteger,
  castNumberInteger,
  isNumberInteger,
  NumberInteger,
  validNumberInteger
} from './number-integer';

// TODO remove and use NumberInteger
export type Integer = NumberInteger;
export const isInteger = isNumberInteger;
export function assertInteger(value: unknown): asserts value is NumberInteger {
  assertNumberInteger(value);
}
export const validInteger = validNumberInteger;
export const castInteger = castNumberInteger;
