import { objectKeys } from './helpers';
import { Integer, validInteger } from './number/integer';

export function assertArray(value: unknown): asserts value is unknown[] {
  if (!Array.isArray(value)) {
    throw new Error('Expected array, got ' + typeof value + '.');
  }
}

export function validArray(value: unknown): unknown[] {
  assertArray(value);
  return value;
}

export function arrayUniqueStr<Item extends string>(list: Item[]): Item[] {
  const uniqueList: { [key in Item]: null } = {} as { [key in Item]: null };
  list.forEach((item) => {
    uniqueList[item] = null;
  });
  return objectKeys(uniqueList);
}

export function arrayToDict<Item, Keys extends string>(
  list: Item[],
  key: (item: Item) => Keys,
  duplicateKey: 'error' | 'first' | 'last' | 'list' = 'error'
): { [key in Keys]: Item } {
  const uniqueList: { [key in Keys]: Item } = {} as { [key in Keys]: Item };
  list.forEach((item, index) => {
    const _key = key(item);
    if (uniqueList.hasOwnProperty(_key)) {
      if (duplicateKey === 'error') {
        if (item === uniqueList[_key]) {
          return;
        }
        console.warn(
          'List contains multiple items with the same key "%s" at index %s and %s:',
          _key,
          list.indexOf(uniqueList[_key]),
          index,
          uniqueList[_key],
          item,
          list
        );
        throw new Error(`Duplicate key ${_key}`);
      }
      if (duplicateKey === 'first') {
        return;
      }
    }
    uniqueList[_key] = item;
  });
  return uniqueList;
}

export function arrayUnique<Item, Keys extends string>(
  list: Item[],
  key: (item: Item) => Keys,
  duplicateKey: 'first' | 'last' = 'first'
): Item[] {
  return Object.values(arrayToDict(list, key, duplicateKey));
}

export function arrayToDictArr<Item, Keys extends string>(
  list: Item[],
  key: (item: Item) => Keys
): { [key in Keys]: Item[] } {
  const uniqueList: { [key in Keys]: Item[] } = {} as { [key in Keys]: Item[] };
  list.forEach((item) => {
    if (!uniqueList.hasOwnProperty(key(item))) {
      uniqueList[key(item)] = [];
    }
    uniqueList[key(item)].push(item);
  });
  return uniqueList;
}

export function arrayUniqueById<T extends { id: string }>(list: T[]): T[] {
  return Object.values(arrayToDict(list, (item) => item.id, 'first'));
}

export function arrayHasSameStrItems<T extends string>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  return a.every((item) => b.includes(item)) && b.every((item) => a.includes(item));
}

export function arrayCountDiff(arr: unknown[]): Integer {
  const uniqueList: unknown[] = [];
  arr.forEach((item) => {
    if (!uniqueList.includes(item)) {
      uniqueList.push(item);
    }
  });

  return validInteger(uniqueList.length);
}

export function isOneOf<I, T>(item: I, arr: T[]): item is I & T {
  return arr.includes(item as unknown as T);
}
