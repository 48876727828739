import { ValidNumber } from './valid-number';
import { NumberCommonInteger, NumberCommonIntegerPositive } from './number-common';

export type NumberInteger = ValidNumber & ({ __iq_type_number_integer: never } | NumberCommonInteger);
export type NumberIntegerPositive<WithZero extends true | false = true> = NumberInteger &
  (WithZero extends true ? { __iq_type_number_integer_positive_with_null: never } | NumberCommonIntegerPositive<true>
  : { __iq_type_number_integer_positive_without_null: never } | NumberCommonIntegerPositive<false>);

export function isNumberInteger(value: unknown): value is NumberInteger {
  return typeof value === 'number' && Number.isInteger(value);
}

export function assertNumberInteger(value: unknown): asserts value is NumberInteger {
  if (!isNumberInteger(value)) {
    throw new Error("Expected integer, got '" + value + "'.");
  }
}

export function validNumberInteger(value: unknown): NumberInteger {
  assertNumberInteger(value);
  return value;
}

export function castNumberInteger(value: number): NumberInteger {
  if (!Number.isInteger(value)) {
    value = parseInt(value.toString());
  }
  return validNumberInteger(value);
}
export function castNumberIntegerPositive<WithZero extends true | false>(
  value: number,
  allowZero: WithZero
): NumberIntegerPositive<WithZero> {
  return validNumberInteger(Math.max(allowZero ? 0 : 1, castNumberInteger(value))) as NumberIntegerPositive<WithZero>;
}
