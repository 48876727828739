import { StringNotEmpty } from './string-not-empty';
import { NumberIntegerPositive } from './number/number-integer';

export function isString(value: string | unknown): value is string {
  return typeof value === 'string';
}
export function assertString(value: string | unknown): asserts value is string {
  if (!isString(value)) {
    if (value === null) {
      throw new Error('Expected type string, got null.');
    }
    console.warn('Expected type string, got ' + typeof value + '.', value);
    throw new Error('Expected type string, got ' + typeof value + '.');
  }
}

export function validString(value: string | unknown): string {
  assertString(value);
  return value;
}

export function stringReverse(value: StringNotEmpty): StringNotEmpty;
export function stringReverse(value: string): string {
  return [...value].reverse().join('');
}

export function stringOccurrences(
  haystack: string,
  needle: string,
  allowOverlapping: boolean = false
): NumberIntegerPositive<true> {
  if (needle.length <= 0) {
    return (haystack.length + 1) as NumberIntegerPositive<true>;
  }

  let occurences = 0;
  let pos = 0;
  const step = allowOverlapping ? 1 : needle.length;

  while (pos >= 0) {
    pos = haystack.indexOf(needle, pos);
    if (pos >= 0) {
      ++occurences;
      pos += step;
    }
  }
  return occurences as NumberIntegerPositive<true>;
}
